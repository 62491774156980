import React from "react"
import PropTypes from "prop-types"

const SingleAbout = ({ bioPic, mainContent, title }) => {
  return (
    <section className="image-square left bg-secondary pb-sm-64">
      <div className="col-md-6 image">
        {bioPic && (
          <div className="background-image-holder">
            <img
              alt={bioPic.title}
              className="background-image"
              src={bioPic.file.url}
            />
          </div>
        )}
      </div>
      <div className="col-md-6 col-md-offset-1 content">
        {title && <h3 className="bold mt104">{title}</h3>}
        {mainContent && <p className="mb0 pt2 pb16">{mainContent}</p>}
      </div>
    </section>
  )
}

SingleAbout.propTypes = {
  bioPic: PropTypes.object,
  mainContent: PropTypes.string,
  title: PropTypes.string,
}

export default SingleAbout
